
















import {Component, Vue} from "vue-property-decorator";
import {TrelloAPI} from "@/api/trello/TrelloAPI";

@Component({ name: "Step1" })
export default class Step1 extends Vue {
  protected trelloAPI: TrelloAPI;
  private boardList = [];

  constructor() {
    super();
    this.trelloAPI = new TrelloAPI();
  }

  get boards()
  {
    this.$store.state.boards.then( (list: any) => {
      this.boardList = list; // TODO first load list will be empty
      if(list.length === 0) {
          this.trelloAPI.getBoards().then((response: any) => {
            response.forEach((board: any) => {
              this.$store.commit('addBoards', {
                id: board.id,
                name: board.name,
                isSelected: false,
                closed: board.closed,
                shortUrl: board.shortUrl,
                idOrganisation: board.idOrganization
              });
            });
          });
        }
    });
    return this.boardList;
  }

  get selectedBoardId() {
    return this.$store.state.selectedBoardId;
  }
  set selectedBoardId(value: any) {
    this.$store.commit('updatedSelectedBoardId',{value: value});
  }
}
